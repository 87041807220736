import React from 'react'
import ReactWOW from 'react-wow'
import { redirectToStore, redirectToWeb } from '../../utils/redirect'
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import {isMobile} from 'react-device-detect'

const RegularBanner = ({data}) => {
    let isFirefox
    let isSafari
    if (typeof window !== 'undefined') {
        isFirefox = typeof InstallTrigger !== 'undefined';
        isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
    }
    return (
        <div className="main-product">
            <div className="container">
                <div className="row align-items-center flex-flow">
                    <div className="col-lg-7 col-md-12">
                        <ReactWOW delay='.1s' animation='fadeInUp'>
                            <div className="main-banner-image">
                                <img src={data.image} alt="banner" />
                            </div>
                        </ReactWOW>
                    </div>
                    <div className="col-lg-5 col-md-12">
                        <div className="product-content">
                            <div>
                                {data.title}
                                <p>{data.subtitle}</p>
                                <div style={{display: 'flex'}} className="justify-center-on-sm">
                                    {/* {!isMobile && !isSafari && <div className="option-item">
                                        <a
                                            href="#" 
                                            activeClassName="active"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                redirectToWeb(e); 
                                                trackCustomEvent({
                                                    category: "sign_in_clicked",
                                                    action: "sign_in_clicked",
                                                });
                                        }}
                                            className="default-btn"
                                        >
                                            Sign In <span></span>
                                        </a>
                                    </div>} */}
                                    {!isMobile && !isSafari && <div className="option-item">
                                        <a 
                                            href="#" 
                                            activeClassName="active"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                redirectToStore(e); 
                                                trackCustomEvent({
                                                    category: "download_now_clicked",
                                                    action: "download_now_clicked",
                                                });
                                        }}
                                        className={"default-btn-white"}
                                        >
                                            Download Now <span></span>
                                        </a>
                                    </div>}
                                    {(isMobile || isSafari) && <div className="option-item">
                                        <a 
                                            href="#" 
                                            activeClassName="active"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                redirectToStore(e); 
                                                trackCustomEvent({
                                                    category: "download_now_clicked",
                                                    action: "download_now_clicked",
                                                });
                                        }}
                                        className={"default-btn"}
                                        >
                                            Download Now <span></span>
                                        </a>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RegularBanner