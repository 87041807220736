import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import SEO from '../components/App/SEO'
import { graphql, Link } from 'gatsby'
import Loadable from '@loadable/component'
import HomeBanner from "../components/Index/HomeBanner"
import RegularBanner from '../components/Index/RegularBanner'
import ReversedProductBannerHome from "../components/Index/ReversedProductHome"
import GemBanner from "../components/Index/GemBanner"
import RegularBannerHalf from "../components/Index/RegularBannerHalf"
import moderngDesignImg from "../assets/images/mask-group-phone.webp"
import securityPrivacyImg from '../assets/images/security-privacy.webp'
// import bannerImg from '../assets/images/hero-banner-neww.webp'
import bannerImg from '../assets/images/hero.webp'
import allDevicesImg from '../assets/images/all-devices-updated.webp'
import GemImg from '../assets/images/generative-ai/gemHomePage.webp'
import Customers from '../components/Customers/Customers'

const Testimonials = Loadable(() => import('../components/Index/Testimonials'))
const FeaturedIn = Loadable(() => import('../components/Index/FeaturedIn'))
const Reviews = Loadable(() => import('../components/Index/Reviews'))
const CustomersReviews = Loadable(() => import('../components/Index/CustomersReviews'))
const Features = Loadable(() => import('../components/Index/Features'))
const ReversedBanner = Loadable(() => import('../components/Index/ReversedBanner'))
const ProductBannerHome = Loadable(() => import('../components/Index/ProductBannerHome'))
const OneRowBanner = Loadable(() => import('../components/Index/OneRowBanner'))

const banner = {
  title: <h1><div>The best email</div> experience ever made</h1>,
  subtitle: 'Get BlueMail for free on any platform',
  image: bannerImg
}

const ourProduct1 = {
  title: <h1><div><span>Blue Mail</span></div>Email & Calendar</h1>,
  subtitle: "BlueMail is a free, beautifully designed, cross platform email & calendar app, capable of managing an unlimited number of mail accounts from various providers. It is the perfect replacement for your stock email app.",
  image: <iframe
  width="100%"
  height="400px"
  src="https://www.youtube.com/embed/2Kl8WHTjchY?rel=0"
  srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}img{border-radius: 20px;}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/2Kl8WHTjchY?rel=0><img src=/img/home/today_show.webp alt='Video The Dark Knight Rises: What Went Wrong? – Wisecrack Edition'><span>▶</span></a>`}
  frameBorder="0"
  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
  allowFullScreen
  title="BlueMail - Coolest must have phone apps from Today show"
  ></iframe>
}

const ourProduct2 = {
  title: <h1><div><span>Privacy</span> & <span>Security</span></div></h1>,
  subtitle: 'At BlueMail, the privacy and security of our users is a top priority and we strongly believe that only you should control your data.',
  image: securityPrivacyImg
}

const modernDesign = {
  title: <h2 style={{fontSize: "41px", lineHeight: "41px,", fontWeight: "700"}}><div><span style={{fontSize: "41px", lineHeight: "41px,", fontWeight: "700", color: "#2570F3"}}>Stylish</span> And <span style={{fontSize: "41px", lineHeight: "41px,", fontWeight: "700", color: "#2570F3"}}>Modern</span> Design </div> <div>For Your Team</div></h2>,
  subtitle: '',
  image: moderngDesignImg
}

const download = {
  title: <h1><div>Download BlueMail <span>Today</span></div></h1>,
  subtitle: 'Available Wherever You Email',
  image: allDevicesImg
}

const gemProduct = {
  title: <h1><div><span>BlueMail GemAI</span></div>Generative Email</h1>, 
  subtitle: 'Experience cutting-edge Artificial Intelligence technologies at your fingertips based on ChatGPT. BlueMail GemAI is designed to streamline the process of summarizing and writing emails in a faster and more efficient way.',
  image: GemImg
}


const Home = ({data}) => {
  return (
    <Layout env={data.site.siteMetadata.env}>
      <SEO
        postTitle="BlueMail - The Best Email App for Windows, Mac, Linux, Android and iOS"
        postDescription="BlueMail is a modern, mobile and desktop, powerful Email management tool with a sleek design, unified inbox and support for all your accounts: IMAP, Exchange,POP3."
        postImage="/img/OG/og_image_Home.png"
        postURL=""
        postSEO
      />
      <Navbar />
      <div className="home">
        <HomeBanner banner={banner} />
        <div className="container-fluid"><Link to="/features-functions/generative-email/"><GemBanner data={gemProduct} /></Link></div>
        <ReversedProductBannerHome data={ourProduct1} />
        <Features />
        <div className="container-fluid modergDesign"><ReversedBanner data={modernDesign} /></div>
        <Testimonials />
        <div className="security-privacy"><Link to="/security"><RegularBannerHalf data={ourProduct2} /></Link></div>
        <Customers />
        {/* <Reviews />  */}
        <CustomersReviews />
        {/* <FeaturedIn /> */}
        <div className="download-today"><OneRowBanner data={download} /></div>   
      </div>
      <Footer />
    </Layout>
  )
}

export default Home

export const query = graphql`
query HomePageQuery {
  site {
      siteMetadata {
        title
        env
      }
    }
  }
`
