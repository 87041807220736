import React from 'react'
import ReactWOW from 'react-wow'
import { redirectToStore } from '../../utils/redirect'
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import {isMobile} from 'react-device-detect'
import LearnMoreImg from '../../assets/images/learnMore.svg'
import { Link } from 'gatsby'

const GemBanner = ({data}) => {
    let isFirefox
    let isSafari
    if (typeof window !== 'undefined') {
        isFirefox = typeof InstallTrigger !== 'undefined';
        isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
    }
    return (
        <div className="main-product">
            <div className="container">
                <div className="row align-items-center flex-flow">
                    <div className="col-lg-6 col-md-12">
                        <ReactWOW delay='.1s' animation='fadeInUp'>
                            <div className="main-banner-image">
                                <img src={data.image} alt="banner" />
                            </div>
                        </ReactWOW>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="product-content home">
                            <div>
                                {data.title}
                                <p style={{marginBottom: "15px"}}>{data.subtitle}</p>
                                <div style={{display: 'flex'}} className="justify-center-on-sm">                                    
                                    {!isMobile && !isSafari && <div className="option-item">
                                        <Link style={{color: "#1F6BF1", fontWeight: 700, fontSize: "20px"}} to='/features-functions/generative-email/'>
                                           Learn More <img style={{marginLeft: "10px"}} src={LearnMoreImg} alt="arrow download now"/><span></span>
                                        </Link>
                                    </div>}
                                    {(isMobile || isSafari) && <div className="option-item">
                                        <Link style={{color: "#1F6BF1", fontWeight: 700, fontSize: "20px"}} to='/features-functions/generative-email/'>
                                            Learn More <img style={{marginLeft: "10px"}} src={LearnMoreImg} alt="arrow download now"/><span></span>
                                        </Link>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GemBanner