import React from 'react'
import ReactWOW from 'react-wow'
import bannerImg from '../../assets/images/hero.webp'
import DropDownButton from '../App/DropDownButton'
import {isMobile} from 'react-device-detect'

const HomeBanner = ({banner}) => {
    return (
        <div className="it-services-banner home-banner">
            <div className="container">
                <div className="row align-items-center">
                    <ReactWOW delay='.4s' animation='fadeIn'>
                    <div className="col-lg-6 col-md-12">
                        
                        <div className="main-banner-content">
                            {banner.title}
                            <div className="main-banner-divider" />
                            <p>{banner.subtitle}</p>
                            <DropDownButton />
                        </div>
                        
                    </div>
                    </ReactWOW>

                    <div className="col-lg-6 col-md-12 pr-0 w-991" style={{padding: isMobile ? '0' : ''}}>
                        
                            <div className="main-banner-image main-banner-image-mobile">
                                <img src={banner.image} alt="banner" />
                            </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeBanner