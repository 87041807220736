import React from 'react'
import ReactWOW from 'react-wow'
import { redirectToStore } from '../../utils/redirect'
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import {isMobile} from 'react-device-detect'
import downloadAppArrow from '../../assets/images/download-app-arrow.svg'

const ReversedProductBannerHome = ({data}) => {
    let isFirefox
    let isSafari
    if (typeof window !== 'undefined') {
        isFirefox = typeof InstallTrigger !== 'undefined';
        isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
    }
    return (
        <div className="main-product">
            <div className="container">
                <div className="row align-items-center">
                <div className="col-lg-6 col-md-12">
                        <div className="product-content home pl-0">                            
                            {data.title}
                            <p>{data.subtitle}</p>
                            <div style={{display: 'flex'}} className="justify-center-on-sm">                                    
                                {!isMobile && !isSafari && <div className="option-item">
                                    <a 
                                        href="#" 
                                        activeClassName="active"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            redirectToStore(e); 
                                            trackCustomEvent({
                                                category: "download_now_clicked",
                                                action: "download_now_clicked",
                                            });
                                    }}
                                    className={"default-btn"}
                                    >
                                        Download Now <img style={{marginLeft: "10px"}} src={downloadAppArrow} alt="arrow download now"/><span></span>
                                    </a>
                                </div>}
                                {(isMobile || isSafari) && <div className="option-item">
                                    <a 
                                        href="#" 
                                        activeClassName="active"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            redirectToStore(e); 
                                            trackCustomEvent({
                                                category: "download_now_clicked",
                                                action: "download_now_clicked",
                                            });
                                    }}
                                    className={"default-btn"}
                                    >
                                        Download Now <img style={{marginLeft: "10px"}} src={downloadAppArrow} alt="arrow download now"/><span></span>
                                    </a>
                                </div>}
                            </div>                            
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <ReactWOW delay='.1s' animation='fadeInUp'>
                            <div className="main-banner-image">
                                {data.image}
                            </div>
                        </ReactWOW>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default ReversedProductBannerHome